.profile-container {
  padding: 0.5rem;
  background: #005f73;
  border-radius: 5px;
  color: #B2CFD5;
  margin-top: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 34px;
}

.account-icon {
  background: white;
  height: 48px;
  width: 48px;
  border-width: 2px;
  border-color: #005f73;
  border-style: solid;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
}

.settings-icon:hover {
  color:white;
  cursor: pointer;
}

.account {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.login-out {
  margin-top: -2px;
  cursor: pointer;
}

.sign-out {
  cursor: pointer;
  font-size: 1rem;
  padding-right: 0.5rem;
}

.login-out:hover, .sign-out:hover {
  color: white;
}

.user-name {
  margin-top: -2px;
}

.pfp {
  height: 48px;
  width: 48px;
}