.selected {
  background: #76a897;
}
.unselected {
  background: #94d2bd;
}
.item-card {
  border-radius: 5px;
  overflow: hidden;
  margin: 0.25rem 0rem;
  /* box-shadow: 0px 2px 10px rgb(120,120,120); */
}

.item-name {
  font-weight: 700;
  padding: 0.6rem;
  padding-left: 0.75rem;
  padding-right: 0;
  color: #3B544B;
  flex: 1;
  cursor: pointer;
}

.delete-icon {
  margin-right: 0.5rem;
  color: #ff3333;
  font-size: 1.5rem;
  cursor: pointer;
}

.delete-icon:hover {
  color: #cc3333;
}

.complete-icon, .edit-icon {
  color: #005f73;
  font-size: 1.3rem;
  cursor: pointer;
}

.complete-icon:hover, .edit-icon:hover {
  color: #004c5c;
}

.item-controls {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}