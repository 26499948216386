.title-input {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  width: 100%;
  padding: 0.75rem;
  outline: none;
  font-size: 14px;
}

.add-heading {
  font-weight: 700;
  margin-bottom: 0.75rem;
  font-size: 18px;
}

.form-button {
  border: none;
  outline: none;
  padding: 0.5rem;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.submit {
  background: #005f73;
  color: #E5EFF1; 
}

.submit:hover {
  background: #004c5c;
}

.close {
  background: #ff3333;
  color: #FFEAEA;
}

.close:hover {
  background: #cc3333;
}

.error-message {
  color: #ff3333;
}