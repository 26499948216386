.app {
  display: flex;
  flex-direction: row;
}

.container-left {
  min-width: 400px;
  padding: 1rem;
  background: white;
  margin: 1rem;
  border-radius: 1rem;
  box-shadow: 0px 1px 8px rgb(40,40,40);
  height: calc(100vh - 2rem);
  display: flex;
  flex-direction: column; 
}


.container-right {
  width: 100%;
  padding: 1rem;
  margin: 1rem;
  margin-left: 0;
  background: white;
  border-radius: 1rem;
  box-shadow: 0px 1px 8px rgb(40,40,40);
  height: calc(100vh - 2rem);
}

.list-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0 1.5rem 0;
}

.list-heading {
  font-weight: 700;
  font-size: 20px;
}

.toggle-form {
  font-size: 28px;
  cursor: pointer;
}

.toggle-form:hover {
  color: #333333
}

span {
  display: flex;
  align-items: center;
}

.sign-in-message {
  background: #94d2bd;
  padding: 0.6rem;
  border-radius: 5px;
  color: #3B544B;
  margin-bottom: 1rem;
  font-weight: 700;
  padding-left: 0.75rem;
  font-size: 18px;
  text-align: center;
}