.list-container {
  overflow: hidden;
  height: 100%;
  flex: auto;
}
.hide-scrollbar {
  overflow-y: scroll;
  height: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.heading {
  font-weight: 700;
  font-size: 1rem;
  margin: 0.5rem 0;
}