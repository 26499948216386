@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap);
*, *::before, *::after {
  box-sizing: border-box;
}

/* html {
  background: #005f73;
} */

body {
  margin: 0;

  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.selected {
  background: #76a897;
}
.unselected {
  background: #94d2bd;
}
.item-card {
  border-radius: 5px;
  overflow: hidden;
  margin: 0.25rem 0rem;
  /* box-shadow: 0px 2px 10px rgb(120,120,120); */
}

.item-name {
  font-weight: 700;
  padding: 0.6rem;
  padding-left: 0.75rem;
  padding-right: 0;
  color: #3B544B;
  flex: 1 1;
  cursor: pointer;
}

.delete-icon {
  margin-right: 0.5rem;
  color: #ff3333;
  font-size: 1.5rem;
  cursor: pointer;
}

.delete-icon:hover {
  color: #cc3333;
}

.complete-icon, .edit-icon {
  color: #005f73;
  font-size: 1.3rem;
  cursor: pointer;
}

.complete-icon:hover, .edit-icon:hover {
  color: #004c5c;
}

.item-controls {
  display: flex;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  align-items: center;
}
.list-container {
  overflow: hidden;
  height: 100%;
  flex: auto;
}
.hide-scrollbar {
  overflow-y: scroll;
  height: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.heading {
  font-weight: 700;
  font-size: 1rem;
  margin: 0.5rem 0;
}
.title-input {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  width: 100%;
  padding: 0.75rem;
  outline: none;
  font-size: 14px;
}

.add-heading {
  font-weight: 700;
  margin-bottom: 0.75rem;
  font-size: 18px;
}

.form-button {
  border: none;
  outline: none;
  padding: 0.5rem;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.submit {
  background: #005f73;
  color: #E5EFF1; 
}

.submit:hover {
  background: #004c5c;
}

.close {
  background: #ff3333;
  color: #FFEAEA;
}

.close:hover {
  background: #cc3333;
}

.error-message {
  color: #ff3333;
}
.display-title {
  background: #94d2bd;
  padding: 0.6rem;
  border-radius: 5px;
  color: #3B544B;
  margin-bottom: 1rem;
  font-weight: 700;
  padding-left: 0.75rem;
  font-size: 18px;
}
.title-input {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
  width: 100%;
  padding: 0.75rem;
  outline: none;
  font-size: 14px;
}

.add-heading {
  font-weight: 700;
  margin-bottom: 0.75rem;
  font-size: 18px;
}

.form-button {
  border: none;
  outline: none;
  padding: 0.5rem;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.submit {
  background: #005f73;
  color: #E5EFF1; 
}

.submit:hover {
  background: #004c5c;
}

.close {
  background: #ff3333;
  color: #FFEAEA;
}

.close:hover {
  background: #cc3333;
}
.profile-container {
  padding: 0.5rem;
  background: #005f73;
  border-radius: 5px;
  color: #B2CFD5;
  margin-top: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 34px;
}

.account-icon {
  background: white;
  height: 48px;
  width: 48px;
  border-width: 2px;
  border-color: #005f73;
  border-style: solid;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2px;
}

.settings-icon:hover {
  color:white;
  cursor: pointer;
}

.account {
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.login-out {
  margin-top: -2px;
  cursor: pointer;
}

.sign-out {
  cursor: pointer;
  font-size: 1rem;
  padding-right: 0.5rem;
}

.login-out:hover, .sign-out:hover {
  color: white;
}

.user-name {
  margin-top: -2px;
}

.pfp {
  height: 48px;
  width: 48px;
}
.app {
  display: flex;
  flex-direction: row;
}

.container-left {
  min-width: 400px;
  padding: 1rem;
  background: white;
  margin: 1rem;
  border-radius: 1rem;
  box-shadow: 0px 1px 8px rgb(40,40,40);
  height: calc(100vh - 2rem);
  display: flex;
  flex-direction: column; 
}


.container-right {
  width: 100%;
  padding: 1rem;
  margin: 1rem;
  margin-left: 0;
  background: white;
  border-radius: 1rem;
  box-shadow: 0px 1px 8px rgb(40,40,40);
  height: calc(100vh - 2rem);
}

.list-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0 1.5rem 0;
}

.list-heading {
  font-weight: 700;
  font-size: 20px;
}

.toggle-form {
  font-size: 28px;
  cursor: pointer;
}

.toggle-form:hover {
  color: #333333
}

span {
  display: flex;
  align-items: center;
}

.sign-in-message {
  background: #94d2bd;
  padding: 0.6rem;
  border-radius: 5px;
  color: #3B544B;
  margin-bottom: 1rem;
  font-weight: 700;
  padding-left: 0.75rem;
  font-size: 18px;
  text-align: center;
}
